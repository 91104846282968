
import { defineComponent, onMounted, ref } from 'vue';
import convertNumWithComma from '@/utils/convertNumWithComma';
import Container from '@/components/Container.vue';
import router from '@/router/index';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import { useRoute } from 'vue-router';
import IconBase from '@/components/icons/IconBase.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue';
import FileImage from '@/components/icons/FileImage.vue';
import MailIcon from '@/views/layouts/AppLayout/icons/MailIcon.vue';
import getServerErrorMessage from '@/utils/getServerErrorMessage';
import SetMemberPasswordModal from '@/components/SetMemberPasswordModal.vue';
import MemberIcon from "@/views/layouts/AppLayout/icons/MemberIcon.vue";

export default defineComponent({
  name: 'MemberDetail',
  components: {
    Container,
    IconBase,
    CheckCircleIcon,
    FileImage,
    MailIcon,
    MemberIcon,
    ArrowLeftIcon,
    SetMemberPasswordModal
  },
  mixins: [scrollToTopOnMountMixin],
  props: {},
  setup() {
    const memberDetail = ref<any>(null);
    const memberIdParam = ref<number>();
    const route = useRoute();
    const isOpenMemberPasswordModal = ref(false);

    onMounted(async () => {
      memberIdParam.value = route.params.memberId
        ? parseInt(route.params.memberId as string)
        : undefined;

      // MemberDetail
      if (!memberIdParam.value) {
        alert('There is no member id');
      } else {
        // 저장된 멤버정보를 가져온다.
        await fetchMemberInfo(memberIdParam.value);
      }
    });

    const approvePartner = async (memberId) => {
      if(confirm('파트너를 승인하시겠습니까?')) {
        try {
          await partnerAPI.adminMember.adminMemberPartnerApprove({
            memberId,
          });
          fetchMemberInfo(memberId)

        } catch (e) {
          alert(getServerErrorMessage(e));
        }
      }
    }

    const fetchMemberInfo = async (memberId) => {
      try {
        const { data } = await partnerAPI.adminMember.adminMember({
          memberId,
        });
        memberDetail.value = (data as any).data;

      } catch (e) {
        router.go(-1);
        alert(getServerErrorMessage(e));
      }
    };

    const resetPasswordByEmail = async (memberId) => {
      try {
        const { data } = await partnerAPI.adminMember.adminMemberResetPassword({
          memberId,
        });
        alert(data.message)
        console.log('리셋하기', data.message);
      } catch (error) {
        console.error(error);
        alert(error)
      }
    }

    const formatDate = (date: string) => {
      return formatDatePattern(date, 'yyyy/MM/dd HH:mm');
    };

    const formatDatePattern = (date: string, pattern) => {
      return format(new Date(date), pattern);
    };

    const gotoEdit = () => {
      router.push({
        path: `/app/member/edit/${memberIdParam.value}`
      })
    }

    const onCloseSetMemberPasswordModal = async (memberId) => {
      console.log('onCloseSetMemberPasswordModal ' + memberId);
      isOpenMemberPasswordModal.value = false
    }

    return {
      memberIdParam,
      memberDetail,
      isOpenMemberPasswordModal,
      gotoEdit,
      approvePartner,
      resetPasswordByEmail,
      fetchMemberInfo,
      formatDate,
      formatDatePattern,
      convertNumWithComma,
      onCloseSetMemberPasswordModal
    };
  },
});
