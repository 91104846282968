<template>
  <div
    v-if="isOpen"
    class="
      fixed
      z-50
      inset-0
      bg-black bg-opacity-40
      flex
      items-center
      justify-center
    "
  >
    <div class="bg-white relative">
      <article class="invoice-number-modal">
        <header class="modal-header">
          <h1 class="modal__title">
            회원 비밀번호 변경
          </h1>
          <button class="modal__close-button" @click="onClose">
            <Close />
          </button>
        </header>
        <div class="modal-content">
          <div class="input-part-area flex items-center">
            <label for="memberEmail" class="input__label block default-label">회원넘버</label>
            <span>{{ member.memberId }} </span>
          </div>
          <div class="input-part-area flex items-center">
            <label for="memberEmail" class="input__label block default-label">회원명</label>
            <span>{{ member.name }} </span>
          </div>
          <div class="input-part-area flex items-center">
            <label for="memberEmail" class="input__label block default-label">회원 아이디</label>
            <span>{{ member.email }} </span>
          </div>
          <div class="input-part-area flex items-center">
            <label for="memberMobile" class="input__label block default-label">휴대폰 번호</label>
            <span>{{ member.mobile }} </span>
          </div>
          <div class="input-part-area flex items-center">
            <label for="password" class="input__label block default-label">비밀번호</label>
            <input id="password" v-model="input.password" :type="input.passwordType.value" class="input__input password-input" placeholder="영문, 숫자, 특수문자 조합으로 6~15자를 입력해주세요.">
          </div>
          <div class="input-part-area flex items-center">
            <label for="verifyPassword" class="input__label block default-label">비밀번호 확인</label>
            <input id="verifyPassword" v-model="input.verifyPassword" :type="input.verifyPasswordType.value" class="input__input password-input" placeholder="비밀번호 확인을 위해 위에서 입력한 비밀번호를 한번 더 입력해주세요.">
          </div>
        </div>
        <div class="justify-center confirm-modal-button-area">
          <button class="confirm-modal__button bg-black full" @click="showMemberPassword">비밀번호 보기</button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button class="confirm-modal__button bg-black full" @click="setMemberPassword">비밀번호 변경</button>
        </div>
      </article>
    </div>
  </div>
</template>

<script setup>
import {computed, defineProps, defineEmits, defineComponent, ref} from 'vue';
import Close from '@/components/icons/Close.vue';
import partnerAPI from '@/service/partnerAPI';

// 읽기전용
const props = defineProps({
  isOpen: {
    type: Boolean,
  },
  member: {
    type: Object,
  },
})

// 부모에게 이벤트 보낼 때
const emit = defineEmits(['onClose'])

// 읽기/쓰기 가능
const input = {
  password: '',
  verifyPassword: '',
  passwordType: ref('password'),
  verifyPasswordType: ref('password'),
  memberId: props.member?.memberId,
  memberEmail: props.member?.email
}

async function setMemberPassword() {
  if (input.password == null || input.password === '') {
    alert('비밀번호를 입력하여 주세요.')
    return;
  }

  if (input.verifyPassword == null || input.verifyPassword === '') {
    alert('비밀번호 확인을 입력하여 주세요.')
    return
  }

  if (input.password !== input.verifyPassword) {
    alert('비밀번호와 비밀번호 확인 값이 일치하지 않습니다.')
    return
  }

  try {
    const { data } = await partnerAPI.adminMember.adminMemberPatchPassword({
      "memberId" : input.memberId,
      "adminMemberPatchPasswordDTO" : {
        "password": input.password
      }
    })
    alert('비밀번호가 변경되었습니다.')
  } catch(e) {
    console.error(e);
    alert('비밀번호 재설정에 실패했습니다.')
  }

  onClose();
}

function showMemberPassword() {
  input.passwordType.value = 'text';
  input.verifyPasswordType.value = 'text';
}

function onClose() {
  input.password = '';
  input.verifyPassword = '';
  input.passwordType.value = 'password'
  input.verifyPasswordType.value = 'password'
  emit('onClose', input.memberId);
}
</script>

<style scoped lang="scss">
.invoice-number-modal {
  width: 650px;
  height: 500px;
  .modal {
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 21px 0 30px;
      height: 83px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    &__title {
      font-size: 32px;
      font-weight: 800;
      line-height: 1.21;
    }

    &-content {
      padding: 0 24px;
    }
  }
}
.input {
  &-part-area {
    margin-top: 30px;
    .FormSelect {
      border: 0;
      border-bottom: 1px solid #202020;
      padding-left: 0;
      font-size: 16px;
      width: calc(100% - 120px);
    }

    + .input-part-area {
      margin-top: 16px;
    }

    & + .button-area {
      margin-top: 26px;
    }
  }
  &__input {
    border: 0;
    border-bottom: 1px solid #202020;
    height: 40px;
    color: #202020;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.2;

    &.full {
      width: 100%;
    }

    &.password-input {
      width: calc(100% - 120px);
    }
  }
  &__select {
    border-bottom: 1px solid #202020;
    height: 40px;
    width: calc(100% - 120px);
  }
  &__label {
    color: #202020;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    &.default-label {
      min-width: 120px;
    }
  }
}
.confirm-modal-button-area {
  position: absolute;
  bottom: 32px;
  left: 24px;
  width: calc(100% - 48px);
  display: flex;
  align-items: center;

  .confirm-modal__button {
    height: 60px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    width: 60%;
    color: #fff;

    &-cancel {
      color: #202020;
      width: 37%;
      background: #fff;
      border: 1px solid #202020;
      box-sizing: border-box;
      margin-right: 3%;
    }

    &.full {
      width: 100%;
    }
  }
}
</style>
